<template>
  <div class="h-100-vh ">
    <StoresPagination v-if=" !integration " />
    <StoresIntegration v-else />
  </div>
</template>

<script>
  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";


  export default {
    components: {
      StoresIntegration: () => import('./components/stores-integration'),
      StoresPagination: () => import('./components/stores-pagination'),
    },
    data() {
      return {};
    },
    computed: {
      ...mapState(['integration']),
    },
    methods: {
      ...mapMutations(['setIntegration']),
      ...mapMutations('menu', ['setStepNumber']),
      ...mapActions('stores', ['fnFirstPagination']),
      fnRefresh(done) {
        this.fnFirstPagination();
        done();
      },
    },
    beforeMount(){
      if (this.$route.query.integration) {
        this.setIntegration(true);
      }
    },
    async mounted() {
      this.fnFirstPagination();
      if (this.$route.query.integration) {
        this.setStepNumber(2);
      }
    }
  }
</script>